import { PaginatedResult } from '../../../../../types';
import { PageableRecipe, RecipeBaseModel } from '../../../../types';

export const mapRecipeResponseToObject = (recipes?: PaginatedResult<RecipeBaseModel>) => {
  const defaultMeta = {
    total: 10,
    lastPage: 1,
    currentPage: 1,
    perPage: 10,
    prev: null,
    next: null,
  };

  const mappedRecipes: PageableRecipe[] = (recipes?.data || []).map((recipe) => ({
    id: recipe.id,
    name: recipe.name,
    maxNumberOfPeople: recipe.maxNumberOfPeople,
    complexity: {
      id: recipe.complexity.id,
      name: recipe.complexity.name,
    },
    isPublished: recipe.isPublished,
    categories: recipe.categories.map((cat) => ({
      id: cat.id,
      name: cat.name,
    })),
    isDraft: recipe.isDraft || false,
    isRemoved: recipe.isRemoved || false,
  }));

  return {
    meta: recipes?.meta || defaultMeta,
    data: mappedRecipes,
  };
};

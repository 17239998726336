import { Box, Container, Table, TableContainer } from '@mui/material';
import {
  EnhancedTableHead,
  MainLayout,
  Navigation,
  Pagination,
  TableStyled,
  TableWrapper,
  useTableClick,
  useTableSort,
} from '../../../../components';
import { PaginatedResult } from '../../../../types';
import { PageableUser, UsersHeadCells } from '../../../types';
import { UserEnhancedTableActions } from './user-enhanced-table-actions';
import UserTableBody from './user-table-body';
import usersHeadCells from './users-head-cells';

interface Props {
  data: PaginatedResult<PageableUser>;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UserEnhancedTable: React.FC<Props> = ({ data, onPageChange, onRowsPerPageChange }) => {
  const { data: users, meta } = data;
  const { selected, setSelected, handleClick } = useTableClick();
  const { handleRequestSort, order, orderBy } = useTableSort<UsersHeadCells>();
  const { currentPage, perPage } = meta;
  const page = currentPage > 0 ? currentPage - 1 : 0;

  return (
    <TableWrapper data-testid='enhanched-table'>
      <TableStyled>
        <Box sx={{ width: '100%' }}>
          <Navigation pageTitle='Users'>
            <UserEnhancedTableActions
              user={users.find((user) => user.id === selected[0])}
              selected={selected}
              setSelected={setSelected}
            />
          </Navigation>
          <MainLayout>
            <Container maxWidth='lg' sx={{ ml: 0, pl: '0 !important' }}>
              <TableContainer
                sx={{
                  maxHeight: 'calc(100vh - 350px)',
                  overflow: 'auto',
                  borderRadius: '2px',
                }}
              >
                <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
                  <EnhancedTableHead
                    headCells={usersHeadCells}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <UserTableBody
                    data={data}
                    selected={selected}
                    perPage={perPage}
                    order={order}
                    orderBy={orderBy}
                    handleOnSelect={handleClick}
                  />
                </Table>
              </TableContainer>
              <Pagination
                total={meta.total}
                perPage={perPage}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </Container>
          </MainLayout>
        </Box>
      </TableStyled>
    </TableWrapper>
  );
};

export default UserEnhancedTable;

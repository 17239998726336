import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Box, Button, InputAdornment, ListItemText, MenuItem, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateButton, Dropdown, KQNTextField, Optional } from '../../../../components';
import { Role } from '../../../../constants';
import { useAuthentication, useRecipesFilter } from '../../../../context';
import { CopyIcon, EditIcon } from '../../../../shared';
import { PageableRecipe } from '../../../types';
import { RecipeFilterAction } from '../filter';
import ImportRecipe from '../import-recipe/import-recipe';
import { DeleteRecipeModal, DuplicateRecipeModal } from '../modals';
import RestoreRecipeModal from '../modals/restore-recipe-modal';
import { ViewSwitch } from '../view-switch';

type Props = {
  recipe?: PageableRecipe;
  readonly selected: string[];
  handleView: (view: 'table' | 'grid') => void;
  selectedView?: 'table' | 'grid';
};

export const RecipesEnhancedTableActions: React.FC<Props> = ({
  recipe,
  selected,
  handleView,
  selectedView,
}) => {
  const { query, setQuery, resetFilter } = useRecipesFilter();
  const navigate = useNavigate();
  const { user: authUser } = useAuthentication();
  const [deletePrompt, setDeletePrompt] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openRestore, setOpenRestore] = useState<boolean>(false);
  const [openDuplicate, setOpenDuplicate] = useState<boolean>(false);
  const isTester = authUser?.role === Role.TESTER;

  const handleFilter = () => {
    setOpenFilter((prev) => !prev);
    if (openFilter) {
      resetFilter();
    }
  };

  const handleOnDelete = async () => {
    setDeletePrompt(true);
  };

  const handleOpenDuplicate = () => {
    setOpenDuplicate(true);
  };

  const handleOpenRestore = () => {
    setOpenRestore(true);
  };

  return (
    <Stack direction='column' gap={2} width='100%'>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        gap={2}
        sx={{ pt: 1 }}
      >
        <KQNTextField
          label='Search'
          size='small'
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          sx={{ width: 'calc(40% - 8px)' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>{!query && <SearchIcon />}</InputAdornment>
            ),
          }}
          InputLabelProps={{ sx: { color: 'kqn.darkerGray' } }}
          placeholder='Search'
          type='search'
        />
        <Button
          variant='contained'
          color='info'
          startIcon={<FilterListIcon />}
          data-testid='filter-button'
          endIcon={openFilter ? <CheckIcon /> : null}
          sx={{
            bgcolor: openFilter ? 'kqn.green' : 'kqn.gray',
            color: openFilter ? '#fff' : 'kqn.darkGray',
            minWidth: 'unset',
            ':hover': { bgcolor: openFilter ? 'kqn.green' : 'kqn.gray' },
          }}
          onClick={handleFilter}
        >
          FILTER
        </Button>
        <Box
          display='flex'
          width='100%'
          flexDirection='row'
          alignItems='center'
          gap={4}
          justifyContent='flex-end'
        >
          <ViewSwitch handleView={handleView} selectedView={selectedView} />
          <Stack direction='row' gap={1.5}>
            <ImportRecipe />

            <Optional condition={selected.length === 1 && !isTester}>
              <Dropdown label='ACTION'>
                <MenuItem
                  onClick={() => navigate(`/recipes/create?recipeId=${recipe?.id}`)}
                  data-testid='edit-button'
                >
                  <Stack direction='row' gap={1}>
                    <EditIcon />
                    <ListItemText primary='Edit' />
                  </Stack>
                </MenuItem>
                <MenuItem onClick={handleOpenDuplicate} data-testid='duplicate-recipe-button'>
                  <Stack direction='row' gap={1}>
                    <CopyIcon />
                    <ListItemText primary='Duplicate' />
                  </Stack>
                </MenuItem>
                {recipe?.isRemoved && (
                  <MenuItem
                    onClick={handleOpenRestore}
                    data-testid='restore-button'
                    sx={{ color: 'kqn.green' }}
                  >
                    <Stack direction='row' gap={1}>
                      <SettingsBackupRestoreIcon sx={{ color: 'kqn.green' }} />
                      <ListItemText primary='Restore' />
                    </Stack>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={handleOnDelete}
                  data-testid='delete-button'
                  sx={{ color: 'kqn.coral' }}
                >
                  <Stack direction='row' gap={1}>
                    <DeleteOutlineIcon sx={{ color: 'kqn.coral' }} />
                    <ListItemText primary='Delete' />
                  </Stack>
                </MenuItem>
              </Dropdown>
            </Optional>
            {!isTester && (
              <CreateButton text='ADD RECIPE' onClick={() => navigate('/recipes/create')} />
            )}
          </Stack>
        </Box>
        <Optional condition={openDuplicate}>
          <DuplicateRecipeModal
            recipe={recipe}
            isOpen={openDuplicate}
            onClose={() => setOpenDuplicate(false)}
          />
        </Optional>
        {openRestore && recipe && (
          <RestoreRecipeModal
            recipeId={recipe.id}
            isOpen={openRestore}
            onClose={() => setOpenRestore(false)}
          />
        )}
        <Optional condition={deletePrompt}>
          <DeleteRecipeModal
            isOpen={deletePrompt}
            onClose={() => setDeletePrompt(false)}
            recipeId={recipe?.id}
          />
        </Optional>
      </Stack>
      <Optional condition={openFilter}>
        <RecipeFilterAction />
      </Optional>
    </Stack>
  );
};

import { Stack } from '@mui/material';
import React from 'react';
import { Title } from '../../../../../../../../../components';
import { InputView } from '../../../../../../../../../shared/components/input-view';
import { GrillStep } from '../../../../../../../../types';

type Props = {
  preset: GrillStep;
};

const PresetInfoView: React.FC<Props> = ({ preset }) => {
  const { userInstruction, userInstructionDe, description, descriptionDe, hint, hintDe } = preset;

  return (
    <Stack direction='column' gap={1}>
      <Stack direction='row' justifyContent='space-between' gap={2} sx={{ width: '100%' }}>
        <Stack direction='column' gap={1} width='100%'>
          <Title title='User Instructions' />
          <InputView value={userInstruction} adornment='EN' />
          <InputView value={userInstructionDe} adornment='DE' />
        </Stack>
        <Stack direction='column' gap={1} width='100%'>
          <Title title='Title' />
          <InputView value={description} adornment='EN' />
          <InputView value={descriptionDe} adornment='DE' />
        </Stack>
      </Stack>
      <Stack direction='row' justifyContent='space-between' gap={2} sx={{ width: '100%' }}>
        <Stack direction='column' gap={1} width='100%'>
          <Title title='Hints (Optional)' />
          <InputView value={hint} adornment='EN' />
          <InputView value={hintDe} adornment='DE' />
        </Stack>
        <Stack direction='column' gap={1} width='100%' />
      </Stack>
    </Stack>
  );
};

export default PresetInfoView;

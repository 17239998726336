import { Chip, TableCell } from '@mui/material';
import React from 'react';
import { TableCellStyled } from '../../../../../components';
import { useAuthentication } from '../../../../../context';
import { RecipeHeadCells } from '../../../../types';

interface Props {
  row: RecipeHeadCells;
  onClick: (id: string) => void;
}

export const DisplayState: React.FC<Props> = ({ onClick, row }) => {
  const { user } = useAuthentication();
  const isAdmin = user?.role === 'ADMIN';
  const isPublished = row.isPublished === 'true' ? 1 : 0;
  const isRemoved = row.isRemoved === 'true' ? 1 : 0;

  if (isAdmin && !!isRemoved) {
    return (
      <TableCell>
        <Chip
          label='Removed'
          sx={{
            color: 'kqn.white',
            borderRadius: '8px',
            bgcolor: 'kqn.coral',
          }}
        />
      </TableCell>
    );
  }

  return (
    <TableCellStyled active={isPublished} onClick={() => onClick(row.id)}>
      <Chip
        label={isPublished ? 'Published' : 'Unpublished'}
        sx={{
          color: isPublished ? 'kqn.white' : 'kqn.darkerGray',
          borderRadius: '8px',
          bgcolor: isPublished ? 'kqn.green' : 'kqn.ultraLightGray',
        }}
      />
    </TableCellStyled>
  );
};

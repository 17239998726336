import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useRecipesFilter } from '../../../../context';
import { useDebounceValue } from '../../../../shared';
import { queries } from '../../../queries';
import { CategoryResponse, RecipeBaseModel } from '../../../types';
import RecipeCard, { RECIPE_DEFAULT_HEIGHT } from './recipe-card';
import RecipeLatest from './recipe-latest';

type Props = {
  category: CategoryResponse;
};

const RecipeCategory: React.FC<Props> = ({ category }) => {
  const { query: filterQuery, data: filterData } = useRecipesFilter();
  const {
    category: selectedCategory,
    complexity,
    cookingStart,
    cookingEnd,
    mealType,
    state,
  } = filterData;
  const debouncedQuery = useDebounceValue(filterQuery);
  const [isExpanded, setIsExpanded] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const { data, isLoading, error, isFetchingNextPage, fetchNextPage, isFetching } =
    useInfiniteQuery({
      ...queries.recipes.infinityFilter(category.id, category, {
        query: debouncedQuery,
        mealType,
        cookingStart: cookingStart || 0,
        cookingEnd: cookingEnd || 999999,
        complexity,
        category: selectedCategory,
        state,
      }),
      getNextPageParam: (metadata) => {
        if (metadata.meta.currentPage === metadata.meta.lastPage) {
          return undefined;
        }
        return metadata.meta.currentPage + 1;
      },
    });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  if (!isFetching && !data) return <div>No data available.</div>;
  if (!data) return null;
  if (error) return <div>Error fetching recipes</div>;

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={isExpanded}
      sx={{
        boxShadow: 'none',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary sx={{ px: '0 !important' }}>
        <Stack direction='column' gap={2} width='100%'>
          <Typography
            variant='h6'
            sx={{
              color: 'kqn.darkGray',
              textUnderlinePosition: 'under',
              textDecoration: 'underline',
            }}
          >
            {category.name}
          </Typography>
          {data.pages[0].data.length < 1 ? (
            <Typography variant='body1' align='center' fontWeight='bold'>
              This category contains no recipes
            </Typography>
          ) : (
            <RecipeLatest
              recipes={data.pages[0].data}
              total={data.pages[0].meta.total / 2}
              onShowAll={() => setIsExpanded((prev) => !prev)}
              isExpanded={isExpanded}
            />
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0 !important' }}>
        <Stack
          direction='row'
          gap={1}
          flexWrap='wrap'
          sx={{ maxHeight: `calc((${RECIPE_DEFAULT_HEIGHT}px + 16px) * 2)`, overflowY: 'auto' }}
        >
          {data?.pages.map((pg) =>
            pg.data.map((recipe: RecipeBaseModel, idx) => (
              <div ref={idx === pg.data.length - 1 ? ref : null} key={recipe.id}>
                <RecipeCard recipe={recipe} />
              </div>
            )),
          )}
          {isFetchingNextPage && <div>Loading...</div>}
        </Stack>
        <Stack direction='column' gap={1} width='100%'>
          <Button
            sx={{
              mx: 'auto',
              border: '0.5px solid #717174',
              mt: '0.5rem',
              borderRadius: '0 !important',
            }}
            startIcon={<KeyboardArrowUpIcon className='icon' />}
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            Collapse
          </Button>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default RecipeCategory;

import { Box, Container, Table, TableCell, TableContainer, TableRow } from '@mui/material';
import {
  EnhancedTableHead,
  MainLayout,
  Navigation,
  Pagination,
  TableStyled,
  TableWrapper,
  useTableClick,
  useTableSort,
} from '../../../../components';
import { PaginatedResult } from '../../../../types';
import { IngredientHeadCells, PageableIngredient } from '../../../types';
import { IngredientsEnhancedTableActions } from './ingredient-enhanced-table-actions';
import ingredientHeadCells from './ingredient-head-cells';
import IngredientsTableBody from './ingredients-table-body';

interface Props {
  data: PaginatedResult<PageableIngredient>;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const IngredientEnhancedTable: React.FC<Props> = ({ data, onPageChange, onRowsPerPageChange }) => {
  const { data: ingredients, meta } = data;
  const { selected, setSelected, handleClick } = useTableClick();
  const { handleRequestSort, order, orderBy } = useTableSort<IngredientHeadCells>();
  const { currentPage, perPage } = meta;
  const page = currentPage > 0 ? currentPage - 1 : 0;

  return (
    <TableWrapper>
      <TableStyled>
        <Box sx={{ width: '100%' }}>
          <Navigation pageTitle='Ingredients'>
            <IngredientsEnhancedTableActions
              ingredients={ingredients}
              selected={selected}
              setSelected={setSelected}
            />
          </Navigation>
          <MainLayout>
            <Container maxWidth='md' sx={{ ml: 0, pl: '0 !important' }}>
              <TableContainer
                sx={{ maxHeight: 'calc(100vh - 350px)', overflow: 'auto', borderRadius: '2px' }}
              >
                <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
                  <EnhancedTableHead
                    headCells={ingredientHeadCells}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  >
                    <TableRow>
                      <TableCell align='center' colSpan={3} sx={{ fontWeight: 'bold' }}>
                        INGREDIENT NAME
                      </TableCell>
                      <TableCell align='center' colSpan={5} sx={{ color: '#fff' }} />
                    </TableRow>
                  </EnhancedTableHead>
                  <IngredientsTableBody
                    data={ingredients}
                    selected={selected}
                    perPage={perPage}
                    order={order}
                    orderBy={orderBy}
                    handleOnSelect={handleClick}
                  />
                </Table>
              </TableContainer>
              <Pagination
                total={meta.total}
                perPage={perPage}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </Container>
          </MainLayout>
        </Box>
      </TableStyled>
    </TableWrapper>
  );
};

export default IngredientEnhancedTable;

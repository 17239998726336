import { ConfirmModal } from '../../../../components';
import { useUpdateRecipeState } from '../../mutations/use-update-recipe-state';

interface Props {
  recipeId?: string;
  isOpen: boolean;
  onClose: (event?: any) => void;
}

const RestoreRecipeModal: React.FC<Props> = ({ recipeId, isOpen, onClose }) => {
  const { handleUpdateRecipeState: handleRemoveRecipe } = useUpdateRecipeState();

  const handleOnStateUpdate = async (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!recipeId) return;
    event?.stopPropagation();

    await handleRemoveRecipe({ recipeId, onSuccess: onClose });
  };

  if (!recipeId) return null;

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCofirm={handleOnStateUpdate}
      title='Are you sure you want to restore this recipe?'
    />
  );
};

export default RestoreRecipeModal;

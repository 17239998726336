import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Button, CircularProgress, FormControl, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { KQNTextField, TextInputAndormnet } from '../../../../components';
import { NavigationPaths, QueryKeys } from '../../../../constants';
import { useDebounceValue } from '../../../../shared';
import { RecipeMetadata } from '../../../types';
import { recipeService } from '../../services';
import { useCreateRecipeFromMetadata } from './hooks';
import ImportAvailability from './import-availability';

type Props = {
  metadata: RecipeMetadata | null;
  onClose?: () => void;
};

const MetadataDetails: React.FC<Props> = ({ metadata, onClose }) => {
  const [recipeId, setRecipeId] = useState<string | null>(null);
  const [status, setStatus] = useState<'success' | 'error' | undefined>(undefined);
  const [data, setData] = useState(metadata);
  const { mutateAsync, isLoading } = useCreateRecipeFromMetadata();
  const navigate = useNavigate();
  const debouncedValue = useDebounceValue(data?.general.name || '', 500);

  const { data: validName, isLoading: isValidating } = useQuery({
    queryKey: [QueryKeys.nameValidation, { debouncedValue }],
    queryFn: () => recipeService.validateRecipeName(debouncedValue),
    enabled: !!debouncedValue,
    cacheTime: 0,
  });

  useEffect(() => {
    if (metadata) {
      setData(metadata);
    }
  }, [metadata]);

  const handleSubmit = async () => {
    if (!data) return;

    const { recipeId: id } = await mutateAsync(data);

    if (id) {
      setStatus('success');
      setRecipeId(id);
      return;
    }

    setStatus('error');
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: 'name' | 'nameDe',
  ) => {
    if (!data) return;

    setData((prev) => ({
      ...prev!,
      general: {
        ...prev!.general!,
        [type]: e.target.value || '',
      },
    }));
  };

  if (!data) return null;

  if (status === 'success')
    return (
      <Stack direction='column' width='100%' justifyContent='center' alignItems='center' gap={3}>
        <CheckCircleOutlineOutlinedIcon
          sx={{ width: '60px', height: '60px', color: 'kqn.green' }}
        />
        <Typography>Recipe imported successfully</Typography>{' '}
        <Stack
          direction='row'
          gap={3}
          alignContent='center'
          justifyContent='flex-end'
          pt={3}
          width='100%'
        >
          <Button
            type='button'
            variant='text'
            data-testid='close-button'
            onClick={() => onClose?.()}
          >
            CLOSE
          </Button>
          <Button
            disabled={!recipeId}
            type='button'
            onClick={() =>
              navigate(`${NavigationPaths.DETAILS}?recipeId=${recipeId}`, { replace: true })
            }
            variant='contained'
            startIcon={isLoading && <CircularProgress size={15} sx={{ color: 'kqn.white' }} />}
          >
            GO TO RECIPE
          </Button>
        </Stack>
      </Stack>
    );

  return (
    <Stack>
      <Stack direction='column' gap={2} width='100%' sx={{ pb: 2 }}>
        <FormControl variant='standard'>
          <KQNTextField
            name='name'
            value={data.general.name}
            onChange={(e) => handleOnChange(e, 'name')}
            placeholder='Name (EN)'
            InputProps={{
              startAdornment: <TextInputAndormnet text='EN' position='start' />,
            }}
            helperText={!validName && !isValidating && 'Recipe name already exists.'}
          />
        </FormControl>
        <FormControl variant='standard'>
          <KQNTextField
            name='nameDe'
            value={data.general.nameDe}
            placeholder='Name (DE)'
            onChange={(e) => handleOnChange(e, 'nameDe')}
            InputProps={{
              startAdornment: <TextInputAndormnet text='DE' position='start' />,
            }}
          />
        </FormControl>
      </Stack>
      <ImportAvailability metadata={metadata} />
      <Stack direction='row' gap={3} alignContent='center' justifyContent='flex-end' pt={3}>
        <Button type='button' variant='text' data-testid='close-button' onClick={() => onClose?.()}>
          CANCEL
        </Button>
        <Button
          disabled={!validName}
          type='button'
          onClick={handleSubmit}
          variant='contained'
          data-testid='save-button'
          startIcon={isLoading && <CircularProgress size={15} sx={{ color: 'kqn.white' }} />}
        >
          IMPORT
        </Button>
      </Stack>
    </Stack>
  );
};

export default MetadataDetails;

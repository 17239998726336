import { Box, Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { RecipeBaseModel } from '../../../types';
import RecipeActions from './recipe-actions';

export const RECIPE_DEFAULT_WIDTH = 200;
export const RECIPE_DEFAULT_HEIGHT = 250;

type Props = {
  recipe: RecipeBaseModel;
};

const RecipeCard: React.FC<Props> = ({ recipe }) => {
  const navigate = useNavigate();
  const { imageUri, id } = recipe;

  const handleNavigate = useCallback(() => {
    navigate(`/recipes/details?recipeId=${id}`);
  }, [id, navigate]);

  return (
    <Stack
      direction='column'
      justifyContent='center'
      gap={1}
      sx={{
        width: `${RECIPE_DEFAULT_WIDTH}px`,
        height: `${RECIPE_DEFAULT_HEIGHT}px`,
        borderRadius: '8px',
        position: 'relative',
        overflow: 'hidden',

        '&:hover': {
          cursor: 'pointer',
          bgcolor: 'kqn.grayDark',
        },

        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          height: '40%',
          zIndex: 2,
        },
        '&::before': {
          top: 0,
          background: 'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
        },
        '&::after': {
          bottom: 0,
          background: 'linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
        },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          zIndex: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '.5rem',
          color: 'kqn.white',
          height: '100%',
        }}
        onClick={handleNavigate}
      >
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack direction='row' alignItems='center' gap={0.5}>
            <Typography variant='body1'>{recipe.cookingTime} min</Typography>
          </Stack>
          <Typography variant='body1'>{recipe.complexity.name}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography className='line-clamp' sx={{ maxWidth: '85%' }}>
            {recipe.name}
          </Typography>
          <RecipeActions recipe={recipe} />
        </Stack>
      </Box>
      <img
        src={imageUri}
        alt={recipe.name}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          zIndex: 1,
        }}
      />
    </Stack>
  );
};

export default RecipeCard;
